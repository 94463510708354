import React, { useState } from "react";
import styled from "styled-components";
import SEO from "../components/SEO";
import { Home } from "../components/home";
import {
  ColoredSpan,
  StyledLink,
  StyledA,
  TitleH2Comp,
  CardContainer,
  CommonCard,
  CardH4,
  CardP,
  ReviewCardContainer,
  ReviewCard,
  ReviewLowerSection,
  ReviewCardLowerText,
  ImageSection,
  VisitLink,
  StoreInput,
  DomainInputTab,
  ImageSectionSegmently,
  ImageInputContainer,
  CommonArrowButton,
  ButtonArrow,
  ImageTitleH2,
  ContentLayout,
  CommonColorButton,
  H2Centered,
  PricingTextSection,
} from "../components/common";
import appLogo from "../images/email-before-download-full-logo.svg";
import buttonArrow from "../images/arrow-white.svg";
import carousel1 from "../images/email-before-download-thumbnail-1.png";
import carousel2 from "../images/email-before-download-thumbnail-2.png";
import carousel3 from "../images/email-before-download-thumbnail-3.png";
import imageCampaigns from "../images/campaigns.png";
import imageCustomiseTemplate from "../images/customise-template.png";
import imageDashboard from "../images/dashboard.png";
import imageLeads from "../images/leads.png";
import icon from "../images/icon-seg-1.svg";
import icon2 from "../images/icon-seg-2.svg";
import icon3 from "../images/icon-seg-3.svg";
import icon4 from "../images/icon-seg-4.svg";

const AskFriends = () => {
  const [storeName, setStoreName] = useState("");
  return (
    <>
      <SEO
        title="Ask Friends shopify app"
        description="Allow your customers to ask their friends for purchase advice and let them spread words about your brand on Facebook, Twitter and email."
        noindex
      />
      <Home
        segmently
        appLogo={appLogo}
        logoText="email-before-download-app-logo"
        homeH1={`Ask Friends app for Shopify`}
        homeText={`Allow your customers to ask their friends for purchase advice and let them spread words about your brand on Facebook, Twitter and email.`}
        imageArray={[
          { src: carousel1, alt: "video thumbline" },
          { src: carousel2, alt: "video thumbline" },
          { src: carousel3, alt: "video thumbline" },
        ]}
        buttonLink="https://apps.shopify.com"
        ButtonText="Add App"
        barColor="#3A6B60"
        alignItems="start"
      />

      <TitleH2Comp
        content={
          <>
            Encourage{" "}
            <ColoredSpan color="#3A6B60">indecisive customers</ColoredSpan> to
            buy from your store
          </>
        }
        barColor="#3A6B60"
      />

      <ContentLayout
        image={
          <img
            src={imageCustomiseTemplate}
            alt="customise template segmently"
            width="100%"
          />
        }
        contentH3={`Doesn’t interrupt the shopping process`}
        contentP1={`Instead the app makes it exciting! The app is designed in a way that while creating the poll and adding the products customer doesn’t leave the site. Oppositely, customer invites friends to the poll page.`}
        imageAlign="1/1"
        textAlign="1/2"
        imageGridColumn="100%"
        textGridColumn="80%"
        gridColumns="35% 34%"
        justifyContent="center"
        new
      />

      <ContentLayout
        image={
          <img src={imageCampaigns} alt="campaigns segmently" width="100%" />
        }
        contentH3="Help doubtful customers make a choice"
        contentP1="When a customer is not sure, the app allows the customer to create a poll and ask friends for a purchase advice instead of leaving your store.
                  Make shopping process fun and let customers buy the best items with the help of their friends."
        imageAlign="1/2"
        textAlign="1/1"
        imageGridColumn="100%"
        textGridColumn="80%"
        gridColumns="34% 38%"
        justifyContent="center"
        new
      />

      <ContentLayout
        image={<img src={imageLeads} alt="leads list segmently" width="100%" />}
        contentH3={`Promote your store and increase conversion`}
        contentP1={`Let your customers spread words about your shop in Facebook, Twitter, and any other resources on the Internet. It can incredibly improve your position in search engines allowing to get more traffic and leads.`}
        imageAlign="1/1"
        textAlign="1/2"
        imageGridColumn="100%"
        textGridColumn="80%"
        gridColumns="35% 34%"
        justifyContent="center"
        new
      />

      <ContentLayout
        image={
          <img src={imageDashboard} alt="dashboard segmently" width="100%" />
        }
        contentH3="Gather feedback on your products"
        contentP1="Check what your customers like and hate in your products, learn what's important to your customers based on their comments, upvotes, downvotes."
        imageAlign="1/2"
        textAlign="1/1"
        imageGridColumn="100%"
        textGridColumn="80%"
        gridColumns="34% 38%"
        justifyContent="center"
        new
      />

      <ContentLayout
        image={
          <img src={imageDashboard} alt="dashboard segmently" width="100%" />
        }
        contentH3="1-click installation"
        contentP1="Very simple to install by one click. Nothing else is needed, just starts working without putting any code lines."
        imageAlign="1/1"
        textAlign="1/2"
        imageGridColumn="100%"
        textGridColumn="80%"
        gridColumns="35% 34%"
        justifyContent="center"
        new
      />

      <ContentLayout
        image={
          <img src={imageDashboard} alt="dashboard segmently" width="100%" />
        }
        contentH3="Customizable layout"
        contentP1="Can be easily customized to fit your theme design. Moreover, a simple and attractive design is developed for comfortable use."
        imageAlign="1/2"
        textAlign="1/1"
        imageGridColumn="100%"
        textGridColumn="80%"
        gridColumns="34% 38%"
        justifyContent="center"
        new
      />

      <H2Centered>Pricing</H2Centered>
      <PricingTextSection>
        <p style={{ textAlign: `center` }}>
          Pay as low as <ColoredSpan color="#3A6B60">$1.49/mo</ColoredSpan> for Ask Friends app and start converting
          indecisive customers to paid today. 7 days free trial. Cancel anytime.
        </p>
      </PricingTextSection>

      {/* <TitleH2Comp
        content={
          <>
            Pricing
            <ColoredSpan color="#3A6B60"> Boost Your Sales.</ColoredSpan>
          </>
        }
        barColor="#3A6B60"
      />
      <CardContainer segmently>
        <CommonCard segmently>
          <img src={icon} alt="Easy & Powerful" />
          <CardH4>Easy & Powerful</CardH4>
          <CardP>Save your time and money using all-in-one app</CardP>
        </CommonCard>
        <CommonCard segmently>
          <img src={icon2} alt="Lightweight" />
          <CardH4>Lightweight</CardH4>
          <CardP>
            We don’t slow down your website. Segment.ly uses highly scalable
            cloud servers
          </CardP>
        </CommonCard>
        <CommonCard segmently>
          <img src={icon3} alt="No Coding Required" />
          <CardH4>No Coding Required</CardH4>
          <CardP>
            All settings are united in visual interface so you can easily setup
            all necessary parameters
          </CardP>
        </CommonCard>
        <CommonCard segmently>
          <img src={icon4} alt="20+ Templates" />
          <CardH4>20+ Templates</CardH4>
          <CardP>
            All settings are united in visual interface so you can easily setup
            all necessary parameters
          </CardP>
        </CommonCard>
      </CardContainer> */}

      <ImageSectionSegmently>
        <ImageTitleH2>Ready to convert indecisive customers to paid?</ImageTitleH2>
        <ImageInputContainer>
          <div style={{ display: `flex` }}>
            <StoreInput
              type="text"
              name="store-domain"
              placeholder="Your Store Name"
              value={storeName}
              onChange={(e) => setStoreName(e.target.value)}
            />
            <DomainInputTab>.myshopify.com</DomainInputTab>
          </div>
          <CommonColorButton
            bgColor="#3A6B60"
            hoverBg="#4773ee"
            onClick={() => {
              window.open(`https://apps.shopify.com`);
            }}
          >
            Try It for Free!
          </CommonColorButton>
        </ImageInputContainer>
      </ImageSectionSegmently>

      <TitleH2Comp
        content={
          <>
            Here’s What
            <ColoredSpan color="#3A6B60"> People Are Saying</ColoredSpan>
          </>
        }
        barColor="#3A6B60"
      />
      <ReviewCardContainer>
        <div>
          <ReviewCard shadowColor="0px 23px 26px #99B3D229">
            <CardP>
              “Using the Facebook like campaign option with a discount code
              really increased our sales. Highly recommended!.”
            </CardP>
            <ReviewLowerSection>
              <ReviewCardLowerText>Core Trainer</ReviewCardLowerText>
              <ReviewCardLowerText date>July 7, 2021</ReviewCardLowerText>
            </ReviewLowerSection>
          </ReviewCard>
          <ReviewCard shadowColor="0px 23px 26px #99B3D229">
            <CardP>
              “Great App! The best thing in the app is the quick service from
              the support team 🙂 LOVE IT”
            </CardP>
            <ReviewLowerSection>
              <ReviewCardLowerText>Inspiring Wave</ReviewCardLowerText>
              <ReviewCardLowerText date>April 22, 2021</ReviewCardLowerText>
            </ReviewLowerSection>
          </ReviewCard>
        </div>
        <div>
          <ReviewCard shadowColor="0px 23px 26px #99B3D229">
            <CardP>
              “Amazing APP! Interface is simple, heaps of features and great
              straight forward flat monthly fee. Highly Recommended.”
            </CardP>
            <ReviewLowerSection>
              <ReviewCardLowerText>Endevz</ReviewCardLowerText>
              <ReviewCardLowerText date>June 10, 2021</ReviewCardLowerText>
            </ReviewLowerSection>
          </ReviewCard>
          <ReviewCard shadowColor="0px 23px 26px #99B3D229">
            <CardP>
              “The app did exactly what I needed. It is simple to use and
              creates a nice look on the page. I don’t like that I cannot remove
              the branding at the bottom, but that is minor. I did have a
              problem with the app and…”
            </CardP>
            <ReviewLowerSection>
              <ReviewCardLowerText>Blue Flamingo Studio</ReviewCardLowerText>
              <ReviewCardLowerText date>March 8, 2021</ReviewCardLowerText>
            </ReviewLowerSection>
          </ReviewCard>
        </div>
        <div>
          <ReviewCard shadowColor="0px 23px 26px #99B3D229">
            <CardP>“Great app. Olaf is always available for questions.”</CardP>
            <ReviewLowerSection>
              <ReviewCardLowerText>
                Bare Skin By Dr Bollmann
              </ReviewCardLowerText>
              <ReviewCardLowerText date>May 7, 2021</ReviewCardLowerText>
            </ReviewLowerSection>
          </ReviewCard>
          <ReviewCard shadowColor="0px 23px 26px #99B3D229">
            <CardP>
              “VERY basic. It doesn’t allow to customize the page, it’s very
              generic. It’s a cool concept, so I hope the app is further
              developed and have better features…”
            </CardP>
            <ReviewLowerSection>
              <ReviewCardLowerText>Kallie Shoes</ReviewCardLowerText>
              <ReviewCardLowerText date>April 22, 2021</ReviewCardLowerText>
            </ReviewLowerSection>
          </ReviewCard>
        </div>
      </ReviewCardContainer>
      <ImageSection>
        <ImageTitleH2>
          We Touched The Life Of{" "}
          <ColoredSpan color="#2D2D51"> 600+</ColoredSpan> Merchants’ Custom
          Development
        </ImageTitleH2>
        <VisitLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://apps.shopify.com/partners/appscom-io"
        >
          <CommonArrowButton>
            <div>Browse Apps</div>
            <ButtonArrow>
              <img
                src={buttonArrow}
                alt="arrow"
                style={{ marginLeft: `10px` }}
              />
            </ButtonArrow>
          </CommonArrowButton>
        </VisitLink>
      </ImageSection>
    </>
  );
};

export default AskFriends;
